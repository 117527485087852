<template>
    <div class="poster-wrapper">
        <div class="title-wrapper">
            <p class="title">“点击复制”复制朋友圈文案</p>
            <img class="down-img" src="https://seetop-1257860468.file.myqcloud.com/html/distributor/dis_icon_jt.png">
        </div>
        <div class="slogan-wrapper">
            {{slogan}}
            <span class="copy-btn" @click="string2Clipboard(slogan)">点击复制</span>
        </div>
        <div class="title-wrapper">
            <p class="title">请长按保存您的专属分销海报</p>
            <img class="down-img" src="https://seetop-1257860468.file.myqcloud.com/html/distributor/dis_icon_jt.png">
        </div>
        <qr-poster
                v-if="shortUrl"
                :url="shortUrl"
                :poster="'https://seetop-1257860468.file.myqcloud.com/seetop/uploads/' + poster"
                :text="user"
                :render="render"
                qrPercentWidth="0.20"
                qrPercentHeight="0.20"
                qrPercentX="0.74"
                qrPercentY="1.45"
                textPercentX="0.85"
                textPercentY="1.68"
                textFontSize="25"
        ></qr-poster>
        <float-nav></float-nav>
    </div>
</template>

<script>
  import wx from 'weixin-js-sdk'
  import { globalConfig } from '../../utils/mixin'
  import QrPoster from '../../components/common/QrPoster'
  import { getUrlParams, string2Clipboard } from '../../utils/tools'
  import FloatNav from '../../components/common/FloatNav'

  export default {
    name: 'SalePoster',
    components: { FloatNav, QrPoster },
    mixins: [globalConfig],
    data () {
      return {
        slogan: '',
        shortUrl: '',
        poster: '',
        user: '',
        groupId: '',
        productId: '',
        categoryId: '',
        categoryId2: '',
        render: ''
      }
    },
    methods: {
      string2Clipboard,
      getData: function () {
        const that = this
        this.axios.post(this.apiUrl + 'mall/distributor/getPosterInfo', {
          groupId: this.groupId,
          productid: this.productId,
          categoryId: this.categoryId,
          categoryId2: this.categoryId2,
          render: this.render
        }).then((response) => {
          const data = response.data
          that.slogan = data.info.slogan
          that.shortUrl = data.url
          that.poster = data.info.poster
          that.user = data.user.weixinNick
        })
      }
    },
    created () {
      this.groupId = getUrlParams('groupId')
      this.productId = getUrlParams('goodsId')
      this.categoryId = getUrlParams('categoryId')
      this.categoryId2 = getUrlParams('categoryId2')
      // console.log(this.categoryId2)
      // console.log(this.apiUrl)
      //    20210831新增小程序码，同样修改的还有QrPoster组件
      wx.miniProgram.getEnv(res => {
        if (res.miniprogram) {
          this.render = 'wxappqr'
        }
      })
      this.getData()
    }
  }
</script>

<style scoped>
    .poster-wrapper {}
    .slogan-wrapper {
        font-size: 1rem;
        text-align: center;
        margin-top: 1rem;
        white-space: pre-wrap;
    }
    .title-wrapper {
        display: flex;
        flex-flow: column;
        align-items: center;
        margin: 1rem 0;
    }
    .title {
        width: 100%;
        text-align: center;
        font-size: 3.6vw;
        font-weight: 600;
        color: #666;
        margin-top: 2vw;
    }
    .down-img {
        width: 8vw;
        margin: 1rem 43vw 0;
    }
    .copy-btn {
        border: 1px solid #ff3b30;
        font-size: 2.8vw;
        color: #ff3b30;
        padding: 0.3vw 1vw;
        border-radius: 0.5vw;
    }
</style>
